import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react"
import PropTypes from 'prop-types';



const Alert = ({children, header,message, isOpen, onClose}) => {
    const messages = message.split(',')
    return (<AlertDialog
        motionPreset='slideInBottom'
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='xl' fontWeight='bold'>
            <Heading >{header}</Heading>
            </AlertDialogHeader>
            <AlertDialogBody>
            <UnorderedList>
            {messages.map((msg, index) => {
                return (<ListItem key={index}><Text  fontSize='3xl'>{msg}</Text></ListItem>)
            })}
            </UnorderedList>  
            </AlertDialogBody>
            <AlertDialogFooter>
             {children}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      )
}

Alert.propTypes = {
    children: PropTypes.node.isRequired,
    header: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}
export default Alert