import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay,  FormControl, FormErrorMessage, FormLabel, Heading, Input, Select, Stack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form'
import { useEffect, useMemo } from 'react';
import PhoneNumberInput from '@components/input/PhoneNumberInput';
import { COUNTRIES, getTelCodeCountry } from "@components/input/countries";
import { useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import GroupCheckbox from '@components/checkbox/GroupCheckbox';


const EditMemberModal = ({ isOpen, onClose, member, calendar, onUpdate }) => {
    const countryOptions = COUNTRIES.map(({ name, iso }) => ({
        label: name,
        value: iso
    }));

    const checked = useMemo(() => {
        const checked = [];
        calendar.map(event => {
            if (member.calendar?.includes(event.event_id)) {
                checked.push(event.event_id.toString());
            }
        });
        return checked;
    }, [calendar, member.calendar]);
    const [value, setValue] = useState([...checked])

    const onPhoneNumberChange = (telCode, number) => {
        console.log(`${telCode}${number}`)
        if (number !== "" && number !== undefined && /^\d+$/.test(number) && number.length > 9 && number.length < 16) {
            clearErrors("phone_number")
            setNumber(number)
            setTelCode(telCode)
            console.log(number)
        } else {
            setError("phone_number", {
                type: "manual",
                message: "Enter a valid number!",
            })
        }
    }

    const values = {
        name:  member?.name,
        email:  member?.email,
        department:  member?.department,
    }

    const {
        handleSubmit,
        register,
        setError,
        clearErrors,
        formState: { errors, isSubmitting }
    } = useForm({
        defaultValues: {
            name: "",
            email: "",
            department: "",
        },
        values
    })
      
    const onSubmit = async (data) => {
        data.phone_number = `${telCode}${number}`
        data.country_code = `${telCode}`
        const events  = []
        value.map(event => {
            events.push(parseInt(event))
        })
        data.calendar = events
        console.log(data)
        await onUpdate(data,  member?.id)
        setNumber("")
        onClose()
    }

    const [number, setNumber] = useState("")
    const [country, setCountry] = useState("")
    const [telCode, setTelCode] = useState("")

    useEffect(() => {
        if (member?.id) {
            const phone_number = member?.phone_number
            const country_code = member?.country_code
            const phone = phone_number != "" ? phone_number.substring(country_code.length) : ""
            console.log(phone_number)
            console.log(country_code)
            const countryCode = getTelCodeCountry(country_code)
            setTelCode(country_code)
            setNumber(phone)
            setCountry(countryCode)
            console.log(phone, countryCode)
        }
    },[member])

    useEffect(() => {
        calendar.map(event => {
            if (member.calendar?.includes(event.event_id)) {
                checked.push(event.event_id.toString())
            }
        })
        setValue([...checked])
    }, [calendar, checked, member.calendar])

    return (
        <>
        <Modal closeOnOverlayClick={true} size={'xl'} onClose={onClose} isOpen={isOpen}  blockScrollOnMount={false}  scrollBehavior='inside'>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>{member?.id ? "Edit" : "Add"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)} > 
                    <Stack  spacing={5} direction="column">
                    <Stack spacing={4} justify="space-evenly" >  
                        <Heading size='md'>Member details</Heading> 
                            <FormControl  isInvalid={errors.phone_number && true}>
                                <FormLabel htmlFor='phone_number'>Phone number</FormLabel>
                                <PhoneNumberInput
                                        name="phone_number"
                                        value={number}
                                        country={country}
                                        options={countryOptions}
                                        onChange={(code, value) => onPhoneNumberChange(code, value)}
                                       
                                />
                                {errors.phone_number && (
                                <FormErrorMessage>
                                Please enter a valid number
                                </FormErrorMessage>
                                )}      
                            </FormControl>
                            {/* <FormControl  isInvalid={errors.email && true}>
                                <FormLabel htmlFor='email'>Email</FormLabel>
                                <Input id="email" 
                                    name="email"
                                    type='text'
                                    size='lg'
                                    bg={'gray.100'}
                                    {...register('email', {
                                        required: 'Email is required'
                                    })}
                                />
                                {errors.email && (
                                <FormErrorMessage>
                                Please enter a valid email
                                </FormErrorMessage>
                                )}      
                            </FormControl>   */}
                            <FormControl  isInvalid={errors.name && true}>
                                <FormLabel htmlFor='name'>Name</FormLabel>
                                <Input id="name" 
                                    name="name"
                                    type='text'
                                    size='lg'
                                    bg={'gray.100'}
                                    {...register('name', {
                                        required: 'Name is required'
                                    })}
                                />
                                {errors.name && (
                                <FormErrorMessage>
                                Please enter a name
                                </FormErrorMessage>
                                )}      
                            </FormControl>  
                            <FormControl  isInvalid={errors.department && true}>
                                <FormLabel htmlFor='department'>Department</FormLabel>
                                <Select name="department" 
                                id='department' 
                                variant='filled' 
                                icon={<MdArrowDropDown />}  
                                placeholder='Select department' 
                                {...register('department', {
                                    required: 'Department is required'
                                })}
                                size='lg' >
                                <option value='marketing'>Marketing</option>
                                <option value='finance'>Finance</option>
                                <option value='fnb'>Fnb</option>
                                <option value='tech'>Tech</option>
                                <option value='sales'>Sales</option>
                                <option value='hr'>Hr</option>
                                </Select>
                                {errors.department && (
                                <FormErrorMessage>
                                Please select a department
                                </FormErrorMessage>
                                )}      
                            </FormControl> 

                        </Stack>  
                        <Stack spacing={4} justify="space-evenly" >
                            <Heading size='md'>Events</Heading>   
                            <GroupCheckbox items={calendar} defaults={checked} setValue={setValue}/>
                        </Stack>
                        <Button variant="brandPrimary" mt={2}  size="lg" type="submit" isLoading={isSubmitting} loadingText={member?.id ? "Updating..." : "Adding..."}>
                        {member?.id ? "Edit" : "Add"}
                        </Button>
                    </Stack>
                </form>
                </ModalBody>
            </ModalContent>
        </Modal>  
      </>
    )
}

EditMemberModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    member: PropTypes.object,
    calendar: PropTypes.array.isRequired,
    onUpdate: PropTypes.func.isRequired
}

export default EditMemberModal;
