import { axiosInstance } from "@utility/http/axios";


export const useRestClient = () => {

    const handleError = (error, toast) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          toast({ title: `${error.response.data.data.message}`, status: 'error',  isClosable: true })
          throw error.response.data
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request)
          toast({ title: `Network error!`, status: 'error', isClosable: true })
          throw error.request
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
          toast({ title: `${error.message}`, status: 'error',  isClosable: true })
          throw error.message
        }
    }

    const get = async (
        url,
        data = {},
        headers = {},
      ) => {
        const config = { url, headers, method: 'get', params: data }
        return await axiosInstance.request(config).then(response => response.data)
      }
      
    const post = async (url, data = {}, headers = {}) => {
        return await axiosInstance
          .request({ url, headers, method: 'post', data })
          .then(response => response.data)
    }

    const patch = async (url, data = {}, headers = {}) => {
      return await axiosInstance
        .request({ url, headers, method: 'patch', data })
        .then(response => response.data)
    }
      
    const put = async (url, data = {}, headers = {}) => {
        return await axiosInstance
          .request({ url, headers, method: 'put', data })
          .then(response => response.data)
      }
      
    const del = async (url, data = {}, headers = {}) => {
        return await axiosInstance
          .request({ url, headers, method: 'delete', data })
          .then(response => response.data)
    }

    return {
        get,
        post,
        put,
        del,
        patch,
        handleError
    }
}