import { useRestClient } from "./useRestClient"

export const useCalendar = (toast) => {

    const restClient = useRestClient()
    const addCalendarEvent = async (params) => {
        return restClient.post('/api/v1/calendar', params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })    
    }

    const getCalendarEvents = async (params) => {
        return restClient.get('/api/v1/calendar', params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })    
    }

    const updateCalendarEvent = async (params) => {
        return restClient.patch('/api/v1/calendar', params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })    
    }

    const deleteCalendarEvent = async (params) => {
        return restClient.del('/api/v1/calendar', params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })    
    }

    const addChatbotCalendar = async (short_code, params) => {
        return restClient.post(`/api/v1/chatbots/${short_code}/calendar`, params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })    
    }

    const getChatbotCalendar = async (short_code, params) => {
        return restClient.get(`/api/v1/chatbots/${short_code}/calendar`, params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })    
    }

    const updateChatbotCalendar = async (short_code, params) => {
        return restClient.patch(`/api/v1/chatbots/${short_code}/calendar`, params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })    
    }

    const deleteChatbotCalendar = async (short_code, params) => {
        return restClient.del(`/api/v1/chatbots/${short_code}/calendar`, params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })    
    }

    return {
        addCalendarEvent,
        getCalendarEvents,
        updateCalendarEvent,
        deleteCalendarEvent,
        addChatbotCalendar,
        getChatbotCalendar,
        updateChatbotCalendar,
        deleteChatbotCalendar

    }
}

