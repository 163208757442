import {  Card, CardBody, Heading, Stack, Text, IconButton, useToast, CardHeader, Flex, Box, MenuButton, Menu, MenuList, MenuItem, Spinner, useDisclosure} from '@chakra-ui/react'
import PropTypes from 'prop-types';
import { useState, useRef, useEffect} from 'react';
import { useReactToPrint } from 'react-to-print';
import { MdWhatsapp, MdEdit, MdOutlineViewList, MdContentCopy, MdNotStarted,MdRestartAlt, MdDelete, MdCalendarMonth } from "react-icons/md";
import QRcode from '@components/qrcode/QRcode'
import { BsPrinter, BsThreeDotsVertical } from "react-icons/bs";
import ChatbotDashboard from '@features/chatbots/ChatbotDashboard';


const ChatbotCardV2 = ({info, onUpdate,onMigrate, onDelete, onStart, onRestart,onClear, onRefresh,onDataSync, onQuickBotOn, onQuickBotOff}) => {
    const [isUpdating, setUpdating] = useState(false)
    const [color, setColor] = useState("#25D366")
    const [dashboardLoaded, loadDashboard] = useState(false)
    const redirectTo = `https://docs.google.com/spreadsheets/d/${info?.sheet_id}`
    const whatsappNumber = import.meta.env.VITE_APP_WHATSAPP_NUMBER
    const message = `%2Fstart ${info?.short_code}`
    const number = import.meta.env.VITE_APP_WHATSAPP_NUMBER
    const url = `https://wa.me/${number}?text=${encodeURIComponent(message)}`
    const toast = useToast()
    const componentRef = useRef(null);
    const promiseResolveRef = useRef(null);
    const rawDataSync = import.meta.env.VITE_RAW_DATA_SYNC
    const cookiePrefix = import.meta.env.VITE_COOKIE_PREFIX
    const { onClose: onCalendarClose, onOpen: onCalendarOpen, isOpen: isCalendarOpen } = useDisclosure()



    // We watch for the state to change here, and for the Promise resolve to be available
    useEffect(() => {
    if (color && promiseResolveRef.current) {
        // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
        promiseResolveRef.current();
    }
    }, [color]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setColor("#000000");
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setColor("#25D366");
        }
    });

    const updateChatbot = async () => {
        setUpdating(true)
        await onUpdate(info)
        setUpdating(false)
    }

    const migrateChatbot = async () => {
        setUpdating(true)
        await onMigrate(info)
        setUpdating(false)
    }

    const deleteChatbot = async () => {
        setUpdating(true)
        await onDelete(info)
        setUpdating(false)
    }

    const openSheet = () => {
        window.open(redirectTo, '_blank')
    }

    const openWhatsapp = () => {
        window.open(url, '_blank')
    }

    const copyText = (event, url) => {
        event.preventDefault()
        navigator.clipboard.writeText(`${url}`)
        toast({ title: `Copied to clipboard!`, status: 'success', duration: 3000, isClosable: true })
    }

    const start = async () => {
        setUpdating(true)
        await onStart(info)
        setUpdating(false)
    }

    const restart = async () => {
        setUpdating(true)
        await onRestart(info)
        setUpdating(false)
    }

    const clear = async () => {
        setUpdating(true)
        await onClear(info)
        setUpdating(false)
    }

    const refresh = async () => {
        setUpdating(true)
        await onRefresh(info)
        setUpdating(false)
    }

    const syncData = async () => {
        setUpdating(true)
        await onDataSync(info)
        setUpdating(false)
    }
    const disableQuickBot = async () => {
        setUpdating(true)
        await onQuickBotOff(info)
        setUpdating(false)
    }

    const enableQuickBot = async () => {
        setUpdating(true)
        await onQuickBotOn(info, true)
        setUpdating(false)
    }
       
    const showCalendar = () => {
        loadDashboard(true)
        onCalendarOpen()
    }

    return (
        <Card
            overflow='hidden'
            m={3}
            ref={componentRef} 
            >
            <CardHeader alignContent={"center"} justifyContent={"center"}>
            <Flex spacing='4'>
                <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                    <Box m={2}>
                    <Heading size={{base:"lg", md:"xl"}}>{info?.title}</Heading>
                    </Box>
                </Flex>
                {isUpdating && <Spinner />}
                <IconButton
                    variant='ghost'
                    colorScheme='gray'
                    aria-label='See menu'
                    mr={2}
                    icon={<BsPrinter />}
                    onClick={() => {
                        handlePrint(null, () => componentRef.current);
                    }}
                />
                <Menu  >
                <MenuButton
                    as={IconButton}
                    aria-label='Options'
                    icon={<BsThreeDotsVertical />}
                    variant='outline'
                    placement='right'
                />
                <MenuList>
                <MenuItem icon={<MdWhatsapp/>} onClick={openWhatsapp}>Chat</MenuItem>
                <MenuItem icon={<MdOutlineViewList/>} onClick={openSheet}>View Sheet</MenuItem>
                <MenuItem icon={<MdEdit/>} onClick={updateChatbot}>Update tasks</MenuItem>
                {info?.quick_bot ? (
                    <MenuItem icon={<MdEdit/>} onClick={disableQuickBot}>Disable Quick bot</MenuItem>
                ) : (
                    <MenuItem icon={<MdEdit/>} onClick={enableQuickBot}>Enable Quick bot</MenuItem>
                )}
                {info?.bot_type === 'dynamic' && cookiePrefix === 'xl2bot_dev' && (
                    <>
                    <MenuItem icon={<MdEdit/>} onClick={refresh}>Refresh Flow</MenuItem>
                    <MenuItem icon={<MdNotStarted/>} onClick={start}>Start Flow</MenuItem>
                    <MenuItem icon={<MdRestartAlt/>} onClick={restart}>Restart Flow</MenuItem>
                    <MenuItem icon={<MdRestartAlt/>} onClick={clear}>Clear source</MenuItem>
                    </>
                )}
                {info?.bot_type === 'dynamic' && info?.sheets_row_id_support ===  false && (
                     <MenuItem icon={<MdEdit/>} onClick={migrateChatbot}>Migrate Sheet Format</MenuItem>
                )} 
                {rawDataSync === "true" &&  (
                    <MenuItem icon={<MdRestartAlt/>} onClick={syncData}>Sync Sheet Data</MenuItem>
                )}
                <MenuItem icon={<MdCalendarMonth/>} onClick={showCalendar}>Reports/Reminders</MenuItem>
                <MenuItem icon={<MdDelete/>} onClick={deleteChatbot}>Delete Bot</MenuItem>
                </MenuList>  
                </Menu>
            </Flex>   
             <QRcode code={info?.short_code} color={color}/>
            </CardHeader>    
            <Stack spacing={2} m={2}> 
            <CardBody >
                <Text py='2' fontSize={{base:"2xl", md:"3xl"}}>
                To start the chatbot, you can
                </Text>
                <Text py='2' fontSize={{base:"2xl", md:"3xl"}}>
                1. Scan the QR code or 
                </Text>
                <Text py='2' fontSize={{base:"2xl", md:"3xl"}}>
                2. Send an message <IconButton
                            colorScheme='teal'
                            aria-label='Copy'
                            size='xs'
                            m={2}
                            onClick={(e) => copyText(e, `/start ${info?.short_code}`)}
                            icon={<MdContentCopy />}/>/start {info?.short_code} to {whatsappNumber}
                </Text>   
                </CardBody>
                </Stack> 
                {dashboardLoaded && (
                <ChatbotDashboard isOpen={isCalendarOpen} onClose={onCalendarClose} chatbot={info}/>
                )}
        </Card>
    )

}
ChatbotCardV2.propTypes = {
    info: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onMigrate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onStart: PropTypes.func.isRequired,
    onRestart: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    onRefresh: PropTypes.func.isRequired,
    onDataSync: PropTypes.func.isRequired,
    onQuickBotOn: PropTypes.func.isRequired,
    onQuickBotOff: PropTypes.func.isRequired
};

export default ChatbotCardV2