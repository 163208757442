import { Flex,Stack, FormControl, Input, FormErrorMessage, FormHelperText, Button,  Alert, AlertIcon, useToast, IconButton, Heading, FormLabel, Select, useDisclosure} from '@chakra-ui/react'
import { useForm } from "react-hook-form"
import { MdArrowDropDown, MdContentCopy } from 'react-icons/md'
import { useChatbot } from '@hooks/useChatbot'
import { getUser } from '@utility/UserHelper'
import { useNavigate } from 'react-router-dom'
import { useParams  } from 'react-router-dom'
import TemplateCard from '@components/card/TemplateCard'
import AlertDialog from '@components/dialog/Alert'
import { useState } from 'react'


const CreateChatbot = () => {
    const navigate = useNavigate()
    const toast = useToast()
    const { orgId } = useParams()
    const chatbot = useChatbot(toast)
    const sheetsServiceId = import.meta.env.VITE_SHEET_SERVICE_ID 
    const [infoMessage, setInfoMessage] = useState('')
    const { onClose: onCreateClose, onOpen: onCreateOpen, isOpen: isCreateOpen } = useDisclosure()

    const user = getUser()
    console.log(user)
   
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting }
    } = useForm({ defaultValues: { sheet_url:''}})


    const createChatbot = async (data) => {
        console.log(data)
        const sheetId = data?.sheet_url.split('/')[5]
        const template = data?.template
        console.log(sheetId)
        if (user?.id) {
            try {
                const response = await chatbot.createChatbot({sheet_id: sheetId, template:template})
                console.log(response)
                const message = []
                if (response?.bot_type === 'dynamic') { 
                    if (response?.data_source_count > 0 && response?.task_count > 0) {
                        message.push(`Imported ${response?.task_count} Tasks and ${response?.data_source_count} Source items`)
                    } else if(response?.data_source_count > 0) {
                        message.push(`Imported ${response?.data_source_count} Source items`)
                    } else {
                        message.push(`Imported ${response?.task_count} Tasks.`)
                    }
                } else {
                     message.push(`Imported ${response?.task_count} Tasks`)
                }
                setInfoMessage(message.join(", ")) 
                onCreateOpen()
            }catch(error) {
                console.log(error)
            }
        } else {
            toast({ title: `Please select an or to create a chatbot`, status: 'error', duration: 3000, isClosable: true })
        }
    }

    const onAlertClick = (event, action) => {
        event.preventDefault()
        if (action === 'onCreate') {
            navigate(`/orgs/${orgId}/chatbots`)
        }
        onCreateClose()
    }

    const copyUrl = (event, url) => {
        event.preventDefault()
        navigator.clipboard.writeText(`${url}`)
        toast({ title: `Copied to clipboard!`, status: 'success', duration: 3000, isClosable: true })
      }

    return(
        <Flex width="100vw" height="100vh" flexDirection={"column"} justifyContent="center" alignItems="center">
        <form onSubmit={handleSubmit(createChatbot)}>  
            <Stack direction={'column'} spacing='24px' m={3}>
                <Alert status='info'>
                    <AlertIcon />
                    Before creating the chatbot, please make sure you share the sheet with editor access to the identity mentioned below. 
                </Alert> 
                <Alert status='info'>
                    <AlertIcon />
                        <IconButton
                            colorScheme='teal'
                            aria-label='Copy'
                            size='xs'

                            m={2}
                            onClick={(e) => copyUrl(e, sheetsServiceId)}
                            icon={<MdContentCopy />}/>{sheetsServiceId}
                </Alert> 
                <Heading size={"md"} align="left">Select a template.</Heading>
                <Stack direction={{base: "column", sm:"row"}}>
                    <TemplateCard 
                        header={"Simple task list"}
                        description={"Track daily tasks that need to be done only once a day."}
                        redirectTo={"https://docs.google.com/spreadsheets/d/1m-uEmsUvLxJl22-p28hy1O0s2Vmiygd-mmg5MF5ZRt0"}
                    /> 
                    <TemplateCard 
                        header={"Simple task list with repetitions"}
                        description={"Track daily tasks that have to be repeted more than once a day."}
                        redirectTo={"https://docs.google.com/spreadsheets/d/1UahSxWnbtDZ_hQKcMZB3mPGlrwBO0FKqbvG-NUSwiAU"}
                    /> 
                    <TemplateCard
                        header={"Task list with location checks"}
                        description={"Track daily attendance, number of hours worked etc, checkin verification etc."}
                        redirectTo={"https://docs.google.com/spreadsheets/d/1sddaUBjbFQHU4lIN3lv_ePuw70RKa3mh8lYGedxOFn4"}
                    />
                    <TemplateCard
                        header={"Form filling template"}
                        description={"Collecting data from a customer, via a set of questions."}
                        redirectTo={"https://docs.google.com/spreadsheets/d/1jpcHgGc74KUxtYcIcveFS9bA0abctSIt9d8-1JkW7ZA"}
                    />
                </Stack>
                <Stack >
                <FormControl  isInvalid={errors.template && true}>
                    <FormLabel htmlFor='template'>Select template</FormLabel>
                    <Select name="template" 
                        id='template' 
                        variant='filled' 
                        icon={<MdArrowDropDown />}  
                        placeholder='Select template' 
                        {...register('template', {
                            required: 'Template is required'
                        })}
                        size='lg' >
                        <option value='sop'>SOP task list</option>
                        <option value='form'>Form filling</option>
                    </Select>
                    {errors.template && (
                    <FormErrorMessage>
                    Please select a template
                    </FormErrorMessage>
                    )}      
             </FormControl>    
                <FormControl  isInvalid={errors.sheet_url && true} >
                    <FormLabel htmlFor='template'>Copy your template url here.</FormLabel>
                    <Input
                        id="sheet_url" 
                        name="sheet_url"
                        type='text'
                        variant='outline'
                        size='lg'
                        {...register('sheet_url', {
                            required: true,
                        })}
                    />
                    <FormHelperText>Only google sheets supported</FormHelperText>
                        {errors.sheet_url && (
                    <FormErrorMessage >
                        Please enter a valid google sheet url
                    </FormErrorMessage>
                    )}    
                </FormControl> 
                <Button variant="brandPrimary"  size="lg" type="submit" isLoading={isSubmitting} loadingText="Creating chatbot...">
                    Create chatbot
                </Button>
                </Stack>
            </Stack>
        </form>
        <AlertDialog isOpen={isCreateOpen} onClose={onCreateClose} header={"Create chatbot"} message={infoMessage} >
            <Button label="Ok" onClick={(e) => onAlertClick(e, "onCreate")}/>
         </AlertDialog>   
        </Flex>
    )
}

export default CreateChatbot