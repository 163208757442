import { Center, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Stack, useBreakpointValue, useToast } from "@chakra-ui/react";
import TeamListing from "@features/chatbots/TeamListing";
import CalendarEventsListing from "@features/calendar/chatbot/CalendarEventsListing";
import { useQuery } from "@tanstack/react-query"
import { useTeam  } from '@hooks/useTeam'
import { useCalendar } from '@hooks/useCalendar'
import { useEffect, useMemo, useState } from "react";
import PropType from 'prop-types'
const ChatbotDashboard = ({isOpen, onClose, chatbot}) => {
    const flexDirection = useBreakpointValue({ base: 'column', md: 'row' })
    const toast = useToast()
    const team = useTeam(toast)
    const calendar = useCalendar()

    const [{ pageIndex, pageSize, enabled }, setPagination] =
    useState({
        pageIndex: 0,
        pageSize: 30,
        enabled: false
    })

    const fetchDataOptions = {
        pageIndex,
        pageSize
    }

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
            enabled,
        }),
        [pageIndex, pageSize, enabled]
    )

    const getTeamMembers = async (options) => {
        const response = await team.getTeamMembers({limit: options.pageSize, offset: options.pageIndex * options.pageSize})
        const members = response.items ? response.items : []
        const data = { rows: members, pageCount: Math.ceil(response.total / options.pageSize), pageIndex, pageSize}
        return data
    }
    const dataQuery = useQuery({
        queryKey: ['chatbot_team',  chatbot?.org_id],
        queryFn: () =>  getTeamMembers(fetchDataOptions),
        enabled: !!chatbot?.org_id
    })

    const getCalendarEvents = async (options) => {
        const response = await calendar.getChatbotCalendar(chatbot?.short_code,{limit: options.pageSize, offset: options.pageIndex * options.pageSize})
        const members = response.items ? response.items : []
        const data = { rows: members, pageCount: Math.ceil(response.total / options.pageSize), pageIndex, pageSize}
        return data
    }

    const calendarQuery = useQuery({
        queryKey: ['chatbot_calendar',  chatbot?.short_code],
        queryFn: () =>  getCalendarEvents(fetchDataOptions),
        enabled: !!chatbot?.short_code
    })

    const refreshDashboard = async () => {
        await dataQuery.refetch()
        await calendarQuery.refetch()
    }

    if (dataQuery.isLoading) return <Spinner/>;
    if (calendarQuery.isLoading) return <Spinner/>;

    return (
        <Modal closeOnOverlayClick={true} size={'full'} onClose={onClose} isOpen={isOpen}  blockScrollOnMount={false}  scrollBehavior='inside'>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>Chatbot calendar</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <Center  minHeight = "100vh" minWidth = "100vw">
                    <Flex justifyContent="center" alignItems="center" flexDirection={flexDirection} wrap="wrap">
                        <Stack mt={4} height={"100%"}>
                            <TeamListing teamQuery={dataQuery} calendarQuery={calendarQuery} refresh={refreshDashboard} pagination={pagination} setPagination={setPagination} chatbotId={chatbot?.id} orgId={chatbot?.org_id}/>
                            <CalendarEventsListing calendarQuery={calendarQuery} refresh={refreshDashboard} pagination={pagination} setPagination={setPagination} chatbotCode={chatbot?.short_code}/>
                        </Stack>
                    </Flex>
                </Center> 
        </ModalBody>
        </ModalContent>
        </Modal>       
    )
}

ChatbotDashboard.propTypes = {
    chatbot: PropType.object,
    onClose: PropType.func,
    isOpen: PropType.bool,
}

export default ChatbotDashboard;