import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, FormControl, FormErrorMessage, FormLabel, Heading, Input, Select, Stack} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useForm} from 'react-hook-form'

import { useEffect, useState } from 'react';
import PhoneNumberInput from '@components/input/PhoneNumberInput';
import { COUNTRIES } from "@components/input/countries";
import { MdArrowDropDown } from 'react-icons/md';
import GroupCheckbox from '@components/checkbox/GroupCheckbox';


const AddMemberModal = ({ isOpen, onClose, calendar, onAdd }) => {

    const countryOptions = COUNTRIES.map(({ name, iso }) => ({
        label: name,
        value: iso
    }));

    const [value, setValue] = useState([])

    const onPhoneNumberChange = (telCode, number) => {
        console.log(`${telCode}${number}`)
        if (number !== "" && number !== undefined && /^\d+$/.test(number) && number.length > 9 && number.length < 16) {
            clearErrors("phone_number")
            setNumber(number)
            setTelCode(telCode)
        } else {
            setError("phone_number", {
                type: "manual",
                message: "Enter a valid number!",
            })
        }
    }

    const {
        handleSubmit,
        reset,
        register,
        setError,
        clearErrors,
        formState: { errors, isSubmitting, isSubmitSuccessful }
    } = useForm({
        defaultValues: {
            name: "",
            email: "",
            department: "",
        },
    })
      
    const onSubmit = async (data) => {
        data.phone_number = `${telCode}${number}`
        data.country_code = `${telCode}`
        const events  = []
        value.map(event => {
            events.push(parseInt(event))
        })
        data.calendar = events
        console.log(data)
        await onAdd(data)
        setNumber("")
        onClose()
    }
    const [number, setNumber] = useState("")
    const [telCode, setTelCode] = useState("")

    useEffect(() => {
        if (isSubmitSuccessful) {
          reset(
            {   name: "",
                email: "",
                phone_number: "",
                department: "",
            }
            )
        }
    }, [isSubmitSuccessful, reset])

    const onModalClose = () => {
        console.log("modal close")
        onClose()
        reset(
            {   name: "",
                email: "",
                phone_number: "",
                department: "",
                calendar : [],
                events: [],
            }
        )
    }

    return (
        <>
        <Modal closeOnOverlayClick={true} size={'xl'} onClose={onClose} isOpen={isOpen}  blockScrollOnMount={false}  scrollBehavior='inside'>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>{"Edit"}</ModalHeader>
                <ModalCloseButton onClick={onModalClose}/>
                <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)} > 
                    <Stack  spacing={5} direction="column">
                    <Stack spacing={4} justify="space-evenly" >  
                        <Heading size='md'>Member details</Heading> 
                            <FormControl  isInvalid={errors.phone_number && true}>
                                <FormLabel htmlFor='phone_number'>Phone number</FormLabel>
                                <PhoneNumberInput
                                        name="phone_number"
                                        value={number}
                                        options={countryOptions}
                                        onChange={(code, value) => onPhoneNumberChange(code, value)}
                                       
                                />
                                {errors.phone_number && (
                                <FormErrorMessage>
                                Please enter a valid number
                                </FormErrorMessage>
                                )}      
                            </FormControl>
                            {/* <FormControl  isInvalid={errors.email && true}>
                                <FormLabel htmlFor='email'>Email</FormLabel>
                                <Input id="email" 
                                    name="email"
                                    type='text'
                                    size='lg'
                                    bg={'gray.100'}
                                    {...register('email', {
                                        required: 'Email is required'
                                    })}
                                />
                                {errors.email && (
                                <FormErrorMessage>
                                Please enter a valid email
                                </FormErrorMessage>
                                )}      
                            </FormControl>   */}
                            <FormControl  isInvalid={errors.name && true}>
                                <FormLabel htmlFor='name'>Name</FormLabel>
                                <Input id="name" 
                                    name="name"
                                    type='text'
                                    size='lg'
                                    bg={'gray.100'}
                                    {...register('name', {
                                        required: 'Name is required'
                                    })}
                                />
                                {errors.name && (
                                <FormErrorMessage>
                                Please enter a name
                                </FormErrorMessage>
                                )}      
                            </FormControl>  
                            <FormControl  isInvalid={errors.department && true}>
                                <FormLabel htmlFor='department'>Department</FormLabel>
                                <Select name="department" 
                                id='department' 
                                variant='filled' 
                                icon={<MdArrowDropDown />}  
                                placeholder='Select department' 
                                {...register('department', {
                                    required: 'Department is required'
                                })}
                                size='lg' >
                                <option value='marketing'>Marketing</option>
                                <option value='finance'>Finance</option>
                                <option value='fnb'>Fnb</option>
                                <option value='tech'>Tech</option>
                                <option value='sales'>Sales</option>
                                <option value='hr'>Hr</option>
                                </Select>
                                {errors.department && (
                                <FormErrorMessage>
                                Please select a department
                                </FormErrorMessage>
                                )}      
                            </FormControl> 
                        </Stack>  
                        <Stack spacing={4} justify="space-evenly" >
                            <Heading size='md'>Events</Heading>      
                            <GroupCheckbox items={calendar} defaults={[]}  setValue={setValue}/>
                        </Stack>
                        <Button variant="brandPrimary" mt={2}  size="lg" type="submit" isLoading={isSubmitting} loadingText={"Adding..."}>
                        {"Add"}
                        </Button>
                    </Stack>
                </form>
                </ModalBody>
            </ModalContent>
        </Modal>  
      </>
    )
}

AddMemberModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    calendar: PropTypes.array.isRequired
}

export default AddMemberModal;
