import { Flex, Button, useToast, Stack, Center, useBreakpointValue, useDisclosure} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getUser } from '@utility/UserHelper'
import { useChatbot } from '@hooks/useChatbot'
import ChatbotCardV2 from '@components/card/ChatbotCardV2'
import { useParams } from 'react-router-dom'
import AlertDialog from '@components/dialog/Alert'
import { useEffect, useState } from 'react'

const ChatbotListing = () => {
    const toast = useToast()
    const chatbot = useChatbot(toast)
    const navigate = useNavigate()
    const [infoMessage, setInfoMessage] = useState('')
    const [loadingCount, setLoadingCount] = useState(0)
    const [shortCode,  setShortCode] = useState('')
    const [totalItems,  setTotalItems] = useState(0)
    const [startCount, setStartCount] = useState(false)
    const user = getUser()
    const flexDirection = useBreakpointValue({ base: 'column', md: 'row' })
    const { onClose: onUpdateClose, onOpen: onUpdateOpen, isOpen: isUpdateOpen } = useDisclosure()
    const { onClose: onStartClose, onOpen: onStartOpen, isOpen: isStartOpen } = useDisclosure()
    const { onClose: onRestartClose, onOpen: onRestartOpen, isOpen: isRestartOpen } = useDisclosure()
    const { onClose: onDeleteClose, onOpen: onDeleteOpen, isOpen: isDeleteOpen } = useDisclosure()
    const { onClose: onSyncClose, onOpen: onSyncOpen, isOpen: isSyncOpen } = useDisclosure()
    const { orgId }  = useParams() 
    const [isLoading, setLoading] = useState(false)
    
    console.log(user)
    const onClick = () => {
        navigate(`/orgs/${orgId}/chatbot`)
    }

    const onAlertClick = async (event,shortCode,totalItems, action) => {
        event.preventDefault()
        if (action === 'onUpdate') {
            chatbotsQuery.refetch()
            onUpdateClose()
        } else if (action === 'onSync') {
            chatbotsQuery.refetch()
            onSyncClose()
        } else if (action === 'onDeleteSource') {
            setLoading(true)
            const response = await chatbot.clearSource(shortCode, {})
            console.log(response)
            onDeleteClose()
            chatbotsQuery.refetch()
            setLoading(false)
        } else if (action === 'onStartFlow') {
            setLoading(true)
            setStartCount(true)
            const response = await chatbot.startFlow(shortCode, {})
            console.log(response)
            chatbotsQuery.refetch()
            setLoading(false)
        } else if (action === 'onRestartFlow') {
            setLoading(true)
            setStartCount(true)
            const response = await chatbot.startFlow(shortCode, {})
            console.log(response)
            // chatbotsQuery.refetch()
        } else if (action === 'onStartComplete') {
            setLoading(false)
            onStartClose()
        } else if (action === 'onRestartComplete') {
            setLoading(false)
            onRestartClose()
        }else {
             onUpdateClose()
            onStartClose()
            onRestartClose()
            onDeleteClose()
        }
    }

    const chatbotsQuery = useQuery({
        queryKey: ['user_chatbots', user?.id],
        queryFn: () => chatbot.getChatbots(),
        enabled: !!user?.id,
        initialData: {items: [{id: 1, short_code: "1234", sheet_id: "1", template: "sop"}]}
    })

    const migrateChatbot = async (info) => {
        try {
            const response = await chatbot.migrateChatbot(info?.short_code)
            console.log("Updated",response)
            setInfoMessage("Chatbot successfully migrated.") 
            onUpdateOpen()
        }catch(error) {
            console.log(error)
        }
    }
    const updateChatbot = async (info) => {
        try {
            const response = await chatbot.updateChatbot(info?.short_code, {template: info?.template, sheet_id: info?.sheet_id})
            console.log("Updated",response)
            const message = []
            let editedCount = 0
            let newCount = 0
            const chatbotInfo = response?.chatbot
            const changes = response?.changes
            if (chatbotInfo?.bot_type === 'dynamic') { 
                for (const task of changes) {
                    if (task?.added) {
                        newCount += 1
                        message.push(`${task?.name} added`)
                    }
                    if (task?.edited) {
                        editedCount += 1
                        message.push(`${task?.name} edited`)
                    }
                    if (task?.deleted) {
                        message.push(`${task?.name} deleted`)
                    }
                }
                if (chatbotInfo?.data_source_count > 0 && newCount > 0) {
                    message.push(`Imported ${newCount} Tasks and ${chatbotInfo?.data_source_count} Source items`)
                } else if(chatbotInfo?.data_source_count > 0) {
                    message.push(`Imported ${chatbotInfo?.data_source_count} Source items`)
                }  else {
                    message.push(`Imported ${newCount} Tasks.`)
                    message.push(`Edited ${editedCount} Tasks.`)
                }
            } else {
                 message.push(`Imported ${chatbotInfo?.task_count} Tasks`)
            }
            setInfoMessage(message.join(", ")) 
            onUpdateOpen()
        } catch(error) {
            console.log(error)
        }
    }

    const deleteChatbot = async (info) => {
        const response = await chatbot.deleteChatbot(info?.short_code, {template: info?.template, sheet_id: info?.sheet_id})
        console.log(response)
        chatbotsQuery.refetch()
    }

    const startFlow = async (info) => {
        const response  = await chatbot.getFlowStats(info?.short_code, {})
        console.log(response)
        if (response?.sending > 0) { 
            setShortCode(info?.short_code)
            setInfoMessage(`Sending to ${response?.sending} numbers.`) 
            setTotalItems(response?.sending)
            onStartOpen()
        } else {
            toast({ title: "No source items.", status: 'error',  isClosable: true })
        }
    }

    const restartFlow = async (info) => {
        const restart  = await chatbot.restartFlow(info?.short_code, {})
        console.log(restart)
        const response  = await chatbot.getFlowStats(info?.short_code, {})
        console.log(response)
        if (response?.sending > 0) { 
            setShortCode(info?.short_code)
            setInfoMessage(`Sending to ${response?.sending} numbers.`) 
            setTotalItems(response?.sending)
            onRestartOpen()
        }  else {
            toast({ title: "No source items.", status: 'error',  isClosable: true })
        }
    }

    const clearSource = async (info) => {
        const response  = await chatbot.getFlowStats(info?.short_code, {})
        console.log(response)
        if ((response?.sending + response?.in_progress + response?.delivered) > 0) {
            setShortCode(info?.short_code)
            setInfoMessage(`Deleting ${response?.sending + response?.in_progress + response?.delivered} numbers.`) 
            onDeleteOpen()
        }else {
            toast({ title: "No source items.", status: 'error',  isClosable: true })
        }
    }

    const syncDataSheet = async (info) => {
        const response = await chatbot.syncData(info?.short_code, {})
        console.log(response)
        setInfoMessage(`${response?.total} tasks will be synced to sheet.`)
        onSyncOpen()
    }

    const enableQuickBot = async (info) => {
        const response = await chatbot.quickBot(info?.short_code, {enable_quick_bot: true})
        console.log(response)
    }

    const disableQuickBot = async (info) => {
        const response = await chatbot.quickBot(info?.short_code, {enable_quick_bot: false})
        console.log(response)
    }

    const refreshFlow = async (info) => {
        const response = await chatbot.refreshTasks(info?.short_code, {})
        console.log(response)
    }

    useEffect(() => {
      const maxTime = 60000; // Max time in milliseconds (60 seconds)
      let batchIncrement = 1;

      // Determine the increment batch size based on the number of items
      if (totalItems > 500) {
        batchIncrement = 50; // For large item count, increment by 50
      }

      // Calculate the time interval for each batch increment
      let incrementTime = maxTime / (totalItems / batchIncrement); 

      // Ensure the increment time isn't too fast (at least 1ms per increment)
      if (incrementTime < 1) incrementTime = 1;
        const timer =
          loadingCount < totalItems && setInterval(() => setLoadingCount((prevCount) => prevCount + batchIncrement ), incrementTime);
        return () => {
            setLoading(false)
            clearInterval(timer)
        };
      }, [loadingCount, startCount, totalItems]);
    return (
        <Center minHeight = "100vh" minWidth = "100vw">
            <Flex justifyContent="center" alignItems="center" flexDirection={flexDirection} wrap="wrap">
                <Stack mt={4} height={"100%"}>
                    <Button variant="brandPrimary" size="lg" m={2}  onClick={onClick} >
                    Link Google Sheet
                    </Button>
                    {chatbotsQuery && chatbotsQuery?.data?.items?.map((chatbot) => (
                        <ChatbotCardV2 
                            key={chatbot.id}
                            info={chatbot}
                            onUpdate={()=> updateChatbot(chatbot)}
                            onMigrate={() => migrateChatbot(chatbot)}
                            onDelete={()=> deleteChatbot(chatbot)}
                            onStart={()=> startFlow(chatbot)}
                            onRestart={()=> restartFlow(chatbot)}
                            onClear={()=> clearSource(chatbot)}
                            onRefresh={()=> refreshFlow(chatbot)}
                            onDataSync={()=> syncDataSheet(chatbot)}
                            onQuickBotOn={()=> enableQuickBot(chatbot)}
                            onQuickBotOff={()=> disableQuickBot(chatbot)}
                        />
                    ))}
                </Stack>
            </Flex>
            <AlertDialog isOpen={isUpdateOpen} onClose={onUpdateClose} header={"Chatbot update"} message={infoMessage}>
                <Button onClick={(e) => onAlertClick(e,shortCode,totalItems, "onUpdate")}>Ok </Button>
            </AlertDialog>
            <AlertDialog isOpen={isSyncOpen} onClose={onSyncClose} header={"Sync sheet"} message={infoMessage}>
                <Button onClick={(e) => onAlertClick(e,shortCode,totalItems, "onSync")}>Ok </Button>
            </AlertDialog>
            <AlertDialog isOpen={isDeleteOpen} onClose={onDeleteClose} header={"Delete source"} message={infoMessage}>
            <>
                <Button onClick={(e) => onAlertClick(e,shortCode,totalItems, "onDeleteSource")} isLoading={isLoading}>Confirm</Button>
                <Button onClick={(e) => onAlertClick(e,shortCode,totalItems, "onCancel")} ml={3}>Cancel</Button>
                </>
            </AlertDialog>
            <AlertDialog isOpen={isStartOpen} onClose={onStartClose} header={"Start flow"} message={isLoading ?  `${loadingCount}/${totalItems}`: infoMessage }>
                {isLoading  ? (
                    <Button onClick={(e) => onAlertClick(e,shortCode, "onStartComplete")} isDisabled={isLoading && loadingCount < totalItems}>Ok</Button>
                ) : (
                    <>
                    <Button onClick={(e) => onAlertClick(e,shortCode,totalItems, "onStartFlow")} isLoading={isLoading}>Confirm</Button>
                    <Button onClick={(e) => onAlertClick(e,shortCode,totalItems, "onCancel")} ml={3}>Cancel</Button>
                    </>
                )}
            </AlertDialog>
            <AlertDialog isOpen={isRestartOpen} onClose={onRestartClose} header={"Restart flow"} message={isLoading ?  `${loadingCount}/${totalItems}`: infoMessage }>
            {isLoading  ? (
                    <Button onClick={(e) => onAlertClick(e,shortCode, "onRestartComplete")} isDisabled={isLoading && loadingCount < totalItems}>Ok</Button>
                ) : (
                <>
                <Button onClick={(e) => onAlertClick(e,shortCode,totalItems, "onRestartFlow")} isLoading={isLoading}>Confirm</Button>
                <Button onClick={(e) => onAlertClick(e,shortCode,totalItems, "onCancel")} ml={3}>Cancel</Button>
                </>
            )}
            </AlertDialog>
        </Center>
    )
}

export default ChatbotListing