import { Button, FormControl, FormErrorMessage, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Stack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { MdArrowDropDown } from 'react-icons/md';
import { useForm } from 'react-hook-form';

const CalendarEventModal = ({ isOpen, onClose, onEvent }) => {
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting }
    } = useForm()

    const onSubmit = (data) => {
        data.event_category = "org"
        onEvent(data)
    }
    return (
        <>
        <Modal closeOnOverlayClick={true} size={'xl'} onClose={onClose} isOpen={isOpen} >
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>{event?.event_id ? "Edit event" : "Add event"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <Stack spacing={4} justify="space-evenly" >  
                <form onSubmit={handleSubmit(onSubmit)} >
                    <FormControl  isInvalid={errors.event_type && true}>
                    <FormLabel htmlFor='event_type'>Event type</FormLabel>
                        <Select name="event_type" 
                        id='event_type' 
                        variant='filled' 
                        icon={<MdArrowDropDown />}  
                        placeholder='Select type' 
                        {...register('event_type', {
                            required: 'Event type is required'
                        })}
                        size='lg' >
                        <option value='send_summary_report'>👁️ Summary report</option>
                        <option value='send_task_reminder'>🕒 Task reminder</option>
                        </Select>
                        {errors.event_type && (
                        <FormErrorMessage>
                        Please select a event type
                        </FormErrorMessage>
                        )}      
                    </FormControl> 
                    <FormControl  isInvalid={errors.time_zone && true}>
                    <FormLabel htmlFor='time_zone'>Time zone</FormLabel>
                        <Select name="time_zone" 
                        id='time_zone' 
                        variant='filled' 
                        icon={<MdArrowDropDown />}  
                        placeholder='Select timezone' 
                        {...register('time_zone', {
                            required: 'Event time zone is required'
                        })}
                        size='lg' >
                        <option value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
                            <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                            <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                            <option value="US/Alaska">(GMT-09:00) Alaska</option>
                            <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                            <option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
                            <option value="US/Arizona">(GMT-07:00) Arizona</option>
                            <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                            <option value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</option>
                            <option value="America/Managua">(GMT-06:00) CentralAMerica</option>
                            <option value="US/Central">(GMT-06:00) Central Time (US & Canada)</option>
                            <option value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                            <option value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</option>
                            <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                            <option value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</option>
                            <option value="US/East-Indiana">(GMT-05:00) Indiana (East)</option>
                            <option value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</option>
                            <option value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
                            <option value="America/Manaus">(GMT-04:00) Manaus</option>
                            <option value="America/Santiago">(GMT-04:00) Santiago</option>
                            <option value="Canada/Newfoundland">(GMT-03:30) Newfoundland</option>
                            <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                            <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</option>
                            <option value="America/Godthab">(GMT-03:00) Greenland</option>
                            <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                            <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                            <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                            <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                            <option value="Africa/Casablanca">(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
                            <option value="Etc/Greenwich">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
                            <option value="Europe/Amsterdam">(GMT+01:00)AMsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                            <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                            <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                            <option value="Europe/Sarajevo">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                            <option value="Africa/Lagos">(GMT+01:00) West Central Africa</option>
                            <option value="Asia/Amman">(GMT+02:00)AMman</option>
                            <option value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                            <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                            <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                            <option value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
                            <option value="Europe/Helsinki">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                            <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                            <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                            <option value="Africa/Windhoek">(GMT+02:00) Windhoek</option>
                            <option value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
                            <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                            <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
                            <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                            <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                            <option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
                            <option value="Asia/Baku">(GMT+04:00) Baku</option>
                            <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                            <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                            <option value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
                            <option value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
                            <option value="Asia/Calcutta" selected>(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                            <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                            <option value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
                            <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                            <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                            <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                            <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                            <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                            <option value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
                            <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                            <option value="Australia/Perth">(GMT+08:00) Perth</option>
                            <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
                            <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                            <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                            <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                            <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                            <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                            <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                            <option value="Australia/Canberra">(GMT+10:00) Canberra, Melbourne, Sydney</option>
                            <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                            <option value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
                            <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                            <option value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                            <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                            <option value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                            <option value="Pacific/Tongatapu">(GMT+13:00) Nukualofa</option>
                        </Select>
                        {errors.time_zone && (
                        <FormErrorMessage>
                        Please select time zone.
                        </FormErrorMessage>
                        )}      
                    </FormControl>    
                    <FormControl  isInvalid={errors.event_time && true}>
                        <FormLabel htmlFor='event_time'>Schedule time</FormLabel>
                        <Select name="event_time" 
                            id='event_time' 
                            variant='filled' 
                            icon={<MdArrowDropDown />}  
                            placeholder='Select time' 
                            {...register('event_time', {
                                required: 'Event time is required'
                            })}
                            size='lg' >
                            <option value='00:30'>12:00AM</option>    
                            <option value='00:30'>12:30AM</option>    
                            <option value='1:00'>1:00AM</option>
                            <option value='1:30'>1:30AM</option>     
                            <option value='2:00'>2:00AM</option>
                            <option value='2:30'>2:30AM</option>     
                            <option value='3:00'>3:00AM</option>
                            <option value='3:30'>3:30AM</option>       
                            <option value='4:00'>4:00AM</option>
                            <option value='4:30'>4:30AM</option>    
                            <option value='5:00'>5:00AM</option>
                            <option value='5:30'>5:30AM</option>    
                            <option value='6:00'>6:00AM</option>
                            <option value='6:30'>6:30AM</option>    
                            <option value='7:00'>7:00AM</option>
                            <option value='7:30'>7:30AM</option>
                            <option value='8:00'>8:00AM</option>
                            <option value='8:30'>8:30AM</option>
                            <option value='9:00'>9:00AM</option>
                            <option value='9:30'>9:30AM</option>
                            <option value='10:00'>10:00AM</option>
                            <option value='10:30'>10:30AM</option>
                            <option value='11:00'>11:00AM</option>
                            <option value='11:30'>11:30AM</option>
                            <option value='12:00'>12:00PM</option>
                            <option value='12:30'>12:30PM</option>
                            <option value='13:00'>1:00PM</option>
                            <option value='13:30'>1:30PM</option>
                            <option value='14:00'>2:00PM</option>
                            <option value='14:30'>2:30PM</option>
                            <option value='15:00'>3:00PM</option>
                            <option value='15:30'>3:30PM</option>
                            <option value='16:00'>4:00PM</option>
                            <option value='16:30'>4:30PM</option>
                            <option value='17:00'>5:00PM</option>
                            <option value='17:30'>5:30PM</option>
                            <option value='18:00'>6:00PM</option>
                            <option value='18:30'>6:30PM</option>
                            <option value='19:00'>7:00PM</option>
                            <option value='19:30'>7:30PM</option>
                            <option value='20:00'>8:00PM</option>
                            <option value='20:30'>8:30PM</option>
                            <option value='21:00'>9:00PM</option>
                            <option value='21:30'>9:30PM</option>
                            <option value='22:00'>10:00PM</option>
                            <option value='22:30'>10:30PM</option>
                            <option value='23:00'>11:00PM</option>
                            <option value='23:30'>11:30PM</option>
                        </Select>
                        {errors.event_time && (
                        <FormErrorMessage>
                        Please enter a valid time
                        </FormErrorMessage>
                        )}      
                    </FormControl>  
                    <Button variant="brandPrimary" mt={2}  size="lg" type="submit" isLoading={isSubmitting} loadingText="Adding event...">
                        Add
                    </Button>
                </form>   
                </Stack>     
                </ModalBody>
            </ModalContent>
        </Modal>  
      </>
    )
}

CalendarEventModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onEvent: PropTypes.func.isRequired
}


export default CalendarEventModal;